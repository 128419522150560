import gql from 'graphql-tag';

import { StateContainer } from 'infra-frontend/helpers/apollo';
import { validateEmail } from 'inkp-user-sdk/user';
import { RequestLimitedQuoteOutput, ShareQuoteData, RequestEmailQuoteProduct } from 'inkp-order-sdk/types.g';
import { Product } from 'inkp-product-sdk/types.g';

const COLORS_OPTIONS: Option[] = [
  {
    value: '0',
    display: '0',
  },
  {
    value: '1',
    display: '1',
  },
  {
    value: '2',
    display: '2',
  },
  {
    value: '3',
    display: '3',
  },
  {
    value: '4',
    display: '4',
  },
  {
    value: '5',
    display: '5',
  },
  {
    value: '6',
    display: '6',
  },
  {
    value: '7',
    display: '7',
  },
  {
    value: '8',
    display: '8',
  },
];

export interface Option {
  value: string;
  display: string;
}

export interface ProductDetailState {
  emailQuote: string | null;
  emailQuoteError: string;
  emailQuoteIsValid: boolean;
  items: {
    color: string;
    colorsInBack: number;
    colorsInFront: number;
    hex: string;
    image: string;
    name: string;
    price: number;
    quantity: number;
  }[];
  quoteModalOpen: boolean;
  emailSent: boolean;
  showQuoteMenu: boolean;
  colorsOptions: Option[];
  amountColorsFront: number | null;
  amountColorsBack: number | null;
  phone: string;
  units: number;
  showQuote: boolean;
  getQuote: boolean;
  quote: ShareQuoteData;
  requestedQuotes: RequestLimitedQuoteOutput[];
  product: Product | null;
}

export default class ProductDetailStateContainer extends StateContainer {
  initialState: ProductDetailState = {
    emailQuote: null,
    emailQuoteError: '',
    emailQuoteIsValid: true,
    items: [],
    quoteModalOpen: false,
    emailSent: false,
    showQuoteMenu: false,
    colorsOptions: COLORS_OPTIONS,
    amountColorsFront: null,
    amountColorsBack: null,
    phone: '877-946-5767',
    units: 50,
    showQuote: false,
    getQuote: false,
    quote: {
      oneUnitPrice: 1,
      total: 1,
      quantity: 1,
      quoteTiersValues: [],
    },
    requestedQuotes: [],
    product: null,
  };

  shape = `
    {
      emailQuote
      emailQuoteError
      emailQuoteIsValid
      items {
        color
        colorsInBack
        colorsInFront
        hex
        image
        name
        price
        quantity
      }
      quoteModalOpen
      emailSent
      showQuoteMenu
      colorsOptions {
        value
        display
      }
      amountColorsFront
      amountColorsBack
      phone
      units
      showQuote
      getQuote
      quote {
        oneUnitPrice
        total
        quantity
        quoteTiersValues {
          total
          quantity
          discount
        }
      }
      requestedQuotes {
        printType
        product {
          productItemId
          quantity
        }
        quoteSides {
          FRONT
          BACK
          LEFT
          RIGHT
        }
        amounts {
          adjustment
          blanks
          discount
          printing
          shipping
          subtotal
          tax
          total
          upcharge
        }
      }
      product {
        id
        name
        brand
        categories {
          name
          scope
        }
      }
    }
  `;

  onChangeEmailQuote = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ emailQuote: e.target.value });
  };

  onBlurEmailQuote = (e: React.FocusEvent<HTMLInputElement>) => {
    let emailMessage = null;
    let valid = true;
    const emailValidationResult = validateEmail(e.target.value);

    if (!emailValidationResult.valid) {
      emailMessage = "Email is invalid. The address should be in a format similar to 'user@example.com'.";
      valid = false;
    }

    this.setState({ emailQuoteError: emailMessage, emailQuoteIsValid: valid });
  };

  onSubmitEmailQuote = () => {
    console.log('called onSubmitEmailQuote');
  };

  setModalState = (state: boolean) => {
    return this.setState({ quoteModalOpen: state });
  };

  updateElement = (name: string, value: any) => {
    this.setState((_state: ProductDetailState) => {
      return { [name]: value };
    });
  };

  onSumbitQuote = () => {
    console.log('called onSubmitEmailQuote');
  };
}

export const REQUEST_QUOTE_EMAIL = gql`
  mutation createDesignCartItem(
    $to: String!
    $product: RequestEmailQuoteProduct!
    $items: [RequestEmailQuoteItem!]!
    $requestedQuotes: [RequestLimitedQuoteInput!]!
  ) {
    requestEmailQuote(to: $to, product: $product, items: $items, requestedQuotes: $requestedQuotes)
  }
`;

export const QUOTE_QUERY = gql`
  query Quote($item: LimitedQuoteItems!) {
    requestLimitedQuoteWithTiers(item: $item) {
      printType
      product {
        productItemId
        quantity
      }
      quoteSides {
        FRONT
        BACK
        LEFT
        RIGHT
      }
      amounts {
        adjustment
        blanks
        discount
        printing
        shipping
        subtotal
        tax
        total
        upcharge
      }
    }
  }
`;
