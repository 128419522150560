import React from 'react';
import { ProductColor } from 'inkp-product-sdk/types.g';
import { SHIPPING_SPEED_ENUM } from 'inkp-order-sdk/types.g';
import { isTouchSupported } from '../../../util/touch';

// Components
import ProductDescription from 'inkp-components/dist/Components/ProductDescription';
import ColorPicker from 'inkp-components/dist/Components/ColorPicker';
import PrimaryButton from 'inkp-components/dist/Components/PrimaryButton';
import Button from 'inkp-components/dist/Components/Button';
import WithConditional from 'inkp-components/dist/HOC/WithConditional';
import LightningDetail from 'inkp-components/dist/Components/LightningDetail';
import Warning from 'inkp-components/dist/Components/Warning';
import { prettyProductSize } from 'inkp-product-sdk/product';

import Info from './Info';
import { GetGarmentSizeRange, GetDeliverByDate } from '../../../util/Product';

// import { Color } from '../../../interfaces';

interface Props {
  /** product text description */
  description: string;
  /** product name */
  name: string;
  /** array of available sizes */
  sizes: (string | undefined)[];
  // TODO: get price in cents
  /** price in cents */
  // price: number

  /** function for what to do when size guide is clicked */
  onSizeGuideClick?: () => void;

  /** Array of color objects to display */
  colors: ProductColor[];

  /** Currently active color */
  activeColor: ProductColor;

  /** current label on color picker */
  label: string;

  /** what to do when color is clicked
   * gives access to color name
   */
  onColorSelect: (colorName: string) => void;

  /** Handler for start design click */
  onStartDesign: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

  /** date string reprenting delivery date for lightning product */
  getByDate?: string;

  /** array of strings representing the bulleted 'Product Details' content */
  comments?: string[];
  /** array of strings representing the bulleted 'Why We Love' content */
  weLoveList?: string[];

  /** number of days for SLA, < 3 is truck+ */
  sla: number;

  /** current selection is inStock */
  inStock: boolean;

  /** price in cents */
  price: number;

  /** what to do when Get a Quote is clicked */
  onClickGetAQuote: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ProductDetail = (props: Props) => {
  const {
    description,
    sizes,
    name,
    onSizeGuideClick,
    label,
    colors,
    onColorSelect,
    sla,
    weLoveList,
    comments,
    inStock,
    price,
    onClickGetAQuote,
    onStartDesign,
    activeColor,
  } = props;
  const { minSize, maxSize } = GetGarmentSizeRange(sizes);
  let deliverByDay: string = '';
  if (activeColor && activeColor.sizesObjects && activeColor.sizesObjects.length > 0) {
    const has3DayShipping: boolean = activeColor.sizesObjects.some((sizeObject) => {
      return sizeObject.speed === SHIPPING_SPEED_ENUM.THREE_DAY;
    });
    if (has3DayShipping) {
      deliverByDay = GetDeliverByDate(
        new Date().toString(),
        SHIPPING_SPEED_ENUM.THREE_DAY
      );
    }
  }
  return (
    <div>
      <ProductDescription
        description={description}
        minSize={prettyProductSize(minSize || 'N/A')}
        maxSize={prettyProductSize(maxSize || 'N/A')}
        name={name}
        price={price}
        onSizeGuideClick={onSizeGuideClick}
      >
        {
          // TODO: get price in cents
          // TODO: Add Product tags as children
        }
      </ProductDescription>
      <div className="mt-1p5 mb-2">
        <ColorPicker label={label} colors={colors} onClick={onColorSelect} showTooltip={true} isTouchSupported={isTouchSupported()}/>
      </div>
      <div className="mt-2 mb-1">
        <PrimaryButton size="xl" block={true} disabled={!inStock} onClick={onStartDesign}>
          Start Designing
        </PrimaryButton>
      </div>
      {
        // TODO: redirect to get a quote page
      }
      <div className="mt-1 mb-1p5">
        <Button type="text" size="lg" block={true} onClick={onClickGetAQuote}>
          Get a Quote
        </Button>
      </div>
      {deliverByDay && inStock && (
        <div className="my-1p5">
          <LightningDetail date={deliverByDay} />
        </div>
      )}
      {!inStock && (
        <div className="my-2">
          <Warning
            header="Out of Stock"
            body="Item is currently out of stock. Check other products similar to current item."
          >
            {
              // View Similar Products will be disabled for now
            }
            {/* <div className="flex justify-center bwt-1 bc-red">
              <button className="d-b w-full h-full py-p75 color-red fs-med fw-bold">View Similar Products</button>
            </div> */}
          </Warning>
        </div>
      )}
      <WithConditional conditional={!!weLoveList || !!comments}>
        <div className="my-2 md:mb-0">
          <Info {...{ weLoveList, comments }} />
        </div>
      </WithConditional>
    </div>
  );
};

export default ProductDetail;
