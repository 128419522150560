import _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';
import config from 'inkp-config/public';
import { Product, ProductColor } from 'inkp-product-sdk/types.g';
import {
  LimitedQuoteItems,
  OrderQuoteItemProduct,
  PRINT_TYPES_ENUM,
  QuoteSides,
  RequestEmailQuoteItem,
} from 'inkp-order-sdk/types.g';
import { stringifyProductItemId } from 'inkp-product-sdk/productItem';
import { selectPrintType } from 'inkp-order-sdk/quoter';
import ProductDetailStateContainer, { ProductDetailState, QuoteData } from '../../states/local/productDetail';

// Components
import QuoteCalculator from './quoteCalculator';

interface Props {
  images: { label: string; url: string }[];
  product: Product;
  selectedColor: ProductColor;
  productDetailStateContainer: ProductDetailStateContainer;
  productDetailState: ProductDetailState;
  onBack: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onStartDesign: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface State {}

class QuoteCalculatorMobile extends React.Component<Props, State> {

  componentDidUpdate(prevProps: Props) {
    const prevState: ProductDetailState = prevProps.productDetailState;
    const state: ProductDetailState = this.props.productDetailState;
    if (prevState.getQuote === true && state.getQuote === false && state.showQuote === true) {
      var scrollingElement = document.getElementById('mobile-quote-calculator') || document.body;
      scrollingElement.scrollTo(0, scrollingElement.scrollHeight);
    }
  }

  render() {
    const { images, product, selectedColor, productDetailStateContainer, onBack, onStartDesign } = this.props;
    const { state, updateElement } = productDetailStateContainer as ProductDetailStateContainer;
    const {
      amountColorsBack,
      amountColorsFront,
      colorsOptions,
      getQuote,
      quote,
      showQuote,
      units,
    } = state as ProductDetailState;

    let quoteSides: QuoteSides = {
      FRONT: amountColorsFront || 0,
      BACK: amountColorsBack || 0,
    };
    quoteSides = _.pickBy(quoteSides, (color) => (color && color > 0));
    if (!_.some(quoteSides, (color) => !!color && color > 0)) {
      quoteSides = { FRONT: 1 };
    }

    const productItem: OrderQuoteItemProduct = {
      productItemId: stringifyProductItemId(product.id, selectedColor.name, 'lrg'),
      quantity: units,
    };

    const printType: PRINT_TYPES_ENUM = selectPrintType(quoteSides, [product], [productItem]);
    const item: LimitedQuoteItems = {
      printType,
      product: productItem,
      quoteSides,
    };

    return (
      <QuoteCalculator
        amountColorsBack={amountColorsBack}
        amountColorsFront={amountColorsFront}
        colorsBackOptions={colorsOptions}
        colorsFrontOptions={colorsOptions}
        getQuote={getQuote}
        images={images}
        item={item}
        product={product}
        quote={quote}
        selectedColor={selectedColor}
        showQuote={showQuote}
        units={units}
        onAddUnits={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          updateElement('units', state.units + 1);
          updateElement('emailSent', false);
          return;
        }}
        onBackClick={onBack}
        onChangeBack={(e: React.ChangeEvent<HTMLSelectElement>) => {
          updateElement('amountColorsBack', parseInt(e.target.value, 10));
          updateElement('emailSent', false);
          return;
        }}
        onChangeFront={(e: React.ChangeEvent<HTMLSelectElement>) => {
          updateElement('amountColorsFront', parseInt(e.target.value, 10));
          updateElement('emailSent', false);
          return;
        }}
        onChangeUnits={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue: string = e.currentTarget.value;
          const updatedUnits: number = newValue === '' ? 0 : parseInt(newValue, 10);
          if (isNaN(updatedUnits) || updatedUnits < 0) {
            return;
          }
          updateElement('units', updatedUnits);
          updateElement('emailSent', false);
        }}
        onEmailQuote={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => updateElement('quoteModalOpen', true)}
        onQuoteData={(quote: QuoteData, emailQuoteItem: RequestEmailQuoteItem) => {
          updateElement('items', [emailQuoteItem]);
          updateElement('quote', quote);
          updateElement('getQuote', false);
          updateElement('showQuote', true);
        }}
        onGetQuoteClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          updateElement('getQuote', true);
        }}
        onMinusUnits={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          updateElement('units', state.units <= 1 ? state.units : state.units - 1);
          updateElement('emailSent', false);
          return;
        }}
        onStartDesign={onStartDesign}
      />
    );
  }
}

export default QuoteCalculatorMobile;
