import React from 'react';
import ListToggle from 'inkp-components/dist/HOC/ListToggle';

interface Props {
  /** array of strings representing the bulleted 'Product Details' content */
  comments?: string[];
  /** array of strings representing the bulleted 'Why We Love' content */
  weLoveList?: string[];
}

const defaultProps: Props = {
  comments: [],
  weLoveList: [],
};

const Info: React.FunctionComponent<Props> = (props) => {
  const { comments, weLoveList } = props;
  return (
    <div className="bwb-1 bwt-1 bc-gray">
      <style jsx>{`
        .product-details-list {
          list-style-position: inside;
        }
      `}</style>
      {comments && comments.length > 0 && (
        <div className="py-p75">
          <ListToggle label="Product Details" open={true}>
            {(() => {
              return (
                <ul className="product-details-list mt-p5 p-0">
                  {comments.map((string) => (
                    <li key={string}>{string}</li>
                  ))}
                </ul>
              );
            })()}
          </ListToggle>
        </div>
      )}
      {weLoveList && weLoveList.length > 0 && (
        <div className="py-p75 bwt-1 bc-gray">
          <ListToggle label="Why We Love This">
            {(() => {
              return (
                <ul className="mt-p5">
                  {weLoveList.map((string) => (
                    <li key={string}>{string}</li>
                  ))}
                </ul>
              );
            })()}
          </ListToggle>
        </div>
      )}
    </div>
  );
};

Info.defaultProps = defaultProps;

export default Info;
