import React from 'react';
import { Product } from 'inkp-product-sdk/types.g';

import RelatedProducts from '../../../components/RelatedProducts';
// import { Product } from '../../../interfaces';

// import config from 'inkp-config';

interface Props {
  /** product text description */
  relatedProducts: Product[];
}

const ProductDetail = (props: Props) => {
  const { relatedProducts } = props;
  return (
    <div className="">
      <div className="mb-1 md:mb-2">
        <span className="fw-extra-bold fs-md color-navy md:fs-lg">Related Products</span>
      </div>
      <div className="mt-1">
        <RelatedProducts relatedProducts={relatedProducts} containerClass="w-1/2 mb-1 lg:w-1/4" />
      </div>
    </div>
  );
};

export default ProductDetail;
