import _ from 'lodash';
import * as React from 'react';
import { Product, ProductColor } from 'inkp-product-sdk/types.g';
import {
  LimitedQuoteItems,
  OrderQuoteItemProduct,
  PRINT_TYPES_ENUM,
  QuoteSides,
  RequestEmailQuoteItem,
  RequestLimitedQuoteOutput,
  ShareQuoteData,
} from 'inkp-order-sdk/types.g';
import { stringifyProductItemId } from 'inkp-product-sdk/productItem';
import { selectPrintType } from 'inkp-order-sdk/quoter';
import ProductDetailStateContainer, { ProductDetailState } from '../../states/local/productDetail';

// Components
import RightSidebar from 'inkp-components/dist/Components/RightSidebar';
import QuoteCalculator from './quoteCalculator';

interface Props {
  images: { label: string; url: string }[];
  product: Product;
  productDetailStateContainer: ProductDetailStateContainer;
  productDetailState: ProductDetailState;
  selectedColor: ProductColor;
  onBack: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onStartDesign: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface State {}

class QuoteCalculatorDesktop extends React.Component<Props, State> {
  componentDidUpdate(prevProps: Props) {
    const prevState: ProductDetailState = prevProps.productDetailState;
    const state: ProductDetailState = this.props.productDetailState;
    if (prevState.getQuote === true && state.getQuote === false && state.showQuote === true) {
      var scrollingElement = document.getElementById('desktop-quote-calculator') || document.body;
      scrollingElement.scrollTo(0, scrollingElement.scrollHeight);
    }
  }

  render() {
    const { images, product, selectedColor, onBack, productDetailStateContainer, onStartDesign } = this.props;

    // check these below after everthing is migrated
    const show: boolean = true;
    const display = show ? 'showQuoteDesktop' : 'hideQuoteDesktop';
    const { state, updateElement } = productDetailStateContainer;

    const {
      amountColorsBack,
      amountColorsFront,
      colorsOptions,
      getQuote,
      quote,
      showQuote,
      units,
    } = state as ProductDetailState;

    let quoteSides: QuoteSides = {
      FRONT: amountColorsFront || 0,
      BACK: amountColorsBack || 0,
    };
    quoteSides = _.pickBy(quoteSides, (color) => color && color > 0);
    if (!_.some(quoteSides, (color) => !!color && color > 0)) {
      quoteSides = { FRONT: 1 };
    }

    const productItem: OrderQuoteItemProduct = {
      productItemId: stringifyProductItemId(product.id, selectedColor.name, 'lrg'),
      quantity: units,
    };

    const printType: PRINT_TYPES_ENUM = selectPrintType(quoteSides, [product], [productItem]);
    const item: LimitedQuoteItems = {
      printType,
      product: productItem,
      quoteSides,
    };

    return (
      <RightSidebar
        id="desktop-quote-calculator"
        width="446px"
        show={show}
        title={'Quote Generator'}
        className={`${display}`}
        showHeader={true}
        onBackButtonClick={onBack}
        onOverlayClick={onBack}
        overlay={true}
      >
        <QuoteCalculator
          amountColorsBack={amountColorsBack}
          amountColorsFront={amountColorsFront}
          colorsBackOptions={colorsOptions}
          colorsFrontOptions={colorsOptions}
          getQuote={getQuote}
          images={images}
          item={item}
          product={product}
          quote={quote}
          selectedColor={selectedColor}
          showQuote={showQuote}
          units={units}
          onAddUnits={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            updateElement('units', state.units + 1);
            updateElement('emailSent', false);
            return;
          }}
          onChangeBack={(e: React.ChangeEvent<HTMLSelectElement>) => {
            updateElement('amountColorsBack', parseInt(e.target.value, 10));
            updateElement('emailSent', false);
            return;
          }}
          onChangeFront={(e: React.ChangeEvent<HTMLSelectElement>) => {
            updateElement('amountColorsFront', parseInt(e.target.value, 10));
            updateElement('emailSent', false);
            return;
          }}
          onChangeUnits={(e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue: string = e.currentTarget.value;
            const updatedUnits: number = newValue === '' ? 0 : parseInt(newValue, 10);
            if (isNaN(updatedUnits) || updatedUnits < 0) {
              return;
            }
            updateElement('units', updatedUnits);
            updateElement('emailSent', false);
          }}
          onEmailQuote={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => updateElement('quoteModalOpen', true)}
          onQuoteData={(
            quote: ShareQuoteData,
            emailQuoteItem: RequestEmailQuoteItem,
            requestedQuotes: RequestLimitedQuoteOutput[]
          ) => {
            updateElement('product', product);
            updateElement('items', [emailQuoteItem]);
            updateElement('quote', quote);
            updateElement('requestedQuotes', requestedQuotes);
            updateElement('getQuote', false);
            updateElement('showQuote', true);
          }}
          onGetQuoteClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            updateElement('getQuote', true);
          }}
          onKeyDownUnits={(e: React.KeyboardEvent<HTMLInputElement>) => {
            const keyCode: number = e.keyCode;
            if (keyCode === 38) {
              updateElement('units', state.units + 1);
              return updateElement('emailSent', false);
            }
            if (keyCode === 40) {
              updateElement('units', state.units <= 1 ? state.units : state.units - 1);
              return updateElement('emailSent', false);
            }
          }}
          onMinusUnits={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            updateElement('units', state.units <= 1 ? state.units : state.units - 1);
            updateElement('emailSent', false);
            return;
          }}
          onStartDesign={onStartDesign}
        />
      </RightSidebar>
    );
  }
}

export default QuoteCalculatorDesktop;
