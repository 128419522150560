import {
  TYPE_PRODUCT_CATEGORIES,
  FEATURE_PRODUCT_CATEGORIES,
  COLLAR_PRODUCT_CATEGORIES,
} from 'inkp-product-sdk/constants';

export const UNSPLITTABLE_WORDS = ['beefy-t', 'full-zip', 'quarter-zip', 't-shirt', 'v-neck'];

export const CATEGORIES_MAP = {
  [TYPE_PRODUCT_CATEGORIES.HOODIES]: TYPE_PRODUCT_CATEGORIES.SWEATSHIRTS,
  [FEATURE_PRODUCT_CATEGORIES.ZIPPER]: TYPE_PRODUCT_CATEGORIES.SWEATSHIRTS,
  [COLLAR_PRODUCT_CATEGORIES.CREW_NECK]: TYPE_PRODUCT_CATEGORIES.SWEATSHIRTS,
  [TYPE_PRODUCT_CATEGORIES.T_SHIRTS]: TYPE_PRODUCT_CATEGORIES.T_SHIRTS,
};
