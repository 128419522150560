import React, { Component } from 'react';
import _ from 'lodash';
import Image from 'inkp-components/dist/Components/Image';
import WithNextAndBackArrows from 'inkp-components/dist/HOC/WithNextAndBackArrows';
import Carousel from 'inkp-components/dist/HOC/Carousel';
import classnames from 'classnames';

interface ActiveProductImage {
  url: string;
  label: string;
  active?: boolean;
}

interface Props {
  /** main image object */
  main: ActiveProductImage;
  additional: ActiveProductImage[];
  onImageSelect: (label: string) => void;
  getNextImage: (event: React.MouseEvent) => void;
  getPrevImage: (event: React.MouseEvent) => void;
}

interface State {
  zoomOn: boolean;
  pictureContainerHeight: number;
}

export default class Mockups extends Component<Props, State> {
  private pictureContainerRef: React.RefObject<HTMLDivElement>;
  // private pictureContainerHeight: number = 0;
  private pictureContainerNode: HTMLDivElement | null;
  private debounceReference: EventListener = _.debounce(this.updatePictureContainerHeight, 150).bind(this);

  public state: State;

  constructor(props: Props) {
    super(props);
    this.pictureContainerRef = React.createRef<HTMLDivElement>();
    this.state = {
      zoomOn: false,
      pictureContainerHeight: 0,
    };
  }

  private toggleZoom = (ev: React.MouseEvent<HTMLButtonElement>) => {
    return this.setState((state: any) => {
      return {
        zoomOn: !state.zoomOn,
      };
    });
  };

  private updatePictureContainerHeight() {
    const node = this.pictureContainerRef.current;
    if (node) {
      const clientWidth: number = node.clientWidth;
      const newHeight: number = Math.round((clientWidth * 5) / 4);
      this.setState((state: any) => {
        return {
          pictureContainerHeight: newHeight,
        };
      });
    }
  }

  componentDidMount() {
    this.updatePictureContainerHeight();
    window.addEventListener('resize', this.debounceReference);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceReference);
  }

  render() {
    const { main, additional, onImageSelect, getPrevImage, getNextImage } = this.props;
    const selectedElementIndex: number = additional.findIndex((picture: ActiveProductImage) => {
      return main.url === picture.url;
    });
    const zoomOn: boolean = this.state.zoomOn;
    return (
      <div className={classnames('mockup-container', { 'zoom-on': zoomOn })}>
        <style jsx>{`
          .zoom-on {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: white;
            z-index: 1;
          }
          .zoom-on .close-zoom {
            display: block;
          }
          .zoom-on .controls-container {
            opacity: 0;
          }
          .zoom-on .images-container {
            border-radius: 0;
          }
          .zoom-on .carousel-container {
            display: block;
          }
        `}</style>
        <div className="close-zoom d-n p-p5 bgc-white ta-right">
          <button type="button" className="color-navy" onClick={this.toggleZoom}>
            <i className="mdi mdi-close fs-icon-2" />
          </button>
        </div>
        <div
          className="p-relative"
          ref={this.pictureContainerRef}
          style={{ height: `${this.state.pictureContainerHeight}px` }}
        >
          <div className="images-container w-full h-full flex justify-center items-center bgc-gray-50 br-p5 overflow-hidden">
            <Image src={main.url} />
          </div>
          <div className="controls-container w-full h-full p-absolute pin-t flex flex-col justify-between p-p5">
            <div className="w-full p-absolute pin-t pin-r p-p5 ta-right md:d-n">
              <button className="br-f" onClick={this.toggleZoom}>
                <i className="mdi mdi-magnify-plus-outline fs-icon-2 color-primary" />
              </button>
            </div>
            <div className="w-full flex flex-grow flew-no-shrink flex-basis-auto">
              <WithNextAndBackArrows onLeftClick={getPrevImage} onRightClick={getNextImage} />
            </div>
            <div className="w-full self-end flex justify-center md:d-n">
              <ul className="markers w-2/3 h-p25 flex bgc-white-50 list-reset br-p5 overflow-hidden">
                {additional.map((picture: ActiveProductImage, index: number) => {
                  return (
                    <li
                      key={index}
                      className={classnames('marker flex-grow flex-no-shrink flex-basis-auto', {
                        'bgc-primary': index === selectedElementIndex,
                      })}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="carousel-container mt-1 h-8 d-n md:d-b">
          <Carousel className="h-full">
            {additional.map(({ url, label, active }) => (
              <div className="d-ib mx-p5 h-full align-top" onClick={() => onImageSelect(label)}>
                <Image src={url} className={classnames('w-5 cursor-pointer br-p5', { 'bc-primary bw-1': active })} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    );
  }
}
