const _ = {
  debounce: require('lodash/debounce'),
  uniq: require('lodash/uniq'),
};
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { Query, Mutation } from 'react-apollo';
import { updateURLQuery, url, routes, path } from 'inkp-routes/public';
import { Subscribe, getContainers } from 'infra-frontend/helpers/apollo';
import { Product, ProductColor, ProductSize } from 'inkp-product-sdk/types.g';
import { PRODUCT_CATEGORY_SCOPES } from 'inkp-product-sdk/constants';
import {
  RequestLimitedQuoteInput,
  RequestEmailQuoteItem,
  RequestEmailQuoteProduct,
  PRINT_TYPES_ENUM,
} from 'inkp-order-sdk/types.g';
import Color from 'color';

import Details from './Details';
import Mockups from './Mockups';
import Related from './Related';
import CantFindProduct from '../../components/CantFindProduct';
import { getUrlQuery } from '../../helpers/url';
import { CreateCrumbsFromURL } from '../../helpers/breadcrumbs';
import { getProductDetailsImages, PRODUCT_QUERY, getProductDetailsLink } from '../../util/Product';

// Components
import Breadcrumbs, { Crumb } from 'inkp-components/dist/Components/Breadcrumbs';
import Modal from 'inkp-components/dist/Components/Modal';
import Input from 'inkp-components/dist/Components/Input';
import PrimaryButton from 'inkp-components/dist/Components/PrimaryButton';
import Loading from 'inkp-components/dist/Components/Loading';
import QuoteCalculatorDesktop from '../../components/QuoteCalculatorDesktop';
import QuoteCalculatorMobile from '../../components/QuoteCalculatorMobile';
import SizeGuide from '../../components/SizeGuide';
import { SIZES_BY_BRAND } from '../../components/SizeGuide/constants';

// State Container
import ProductDetailStateContainer, { REQUEST_QUOTE_EMAIL, ProductDetailState } from '../../states/local/productDetail';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes, GTMEvents } from '../../interfaces/GTM';

const ITEMS_NUMBER_FOR_STARTING_PRICE = 50;

interface RouteParams {
  id: string;
  color: string;
}

interface ProductQueryVariables {
  id: string;
}

class ProductQuery extends Query<{ productById?: Product }, ProductQueryVariables> {}

const triggerGTMEvent = ({
  productId,
  colorName,
  gtmEvent,
}: {
  productId: string;
  colorName: string;
  gtmEvent: GTMEvents;
}) => {
  GTM.push(GTMTypes.USER);
  GTM.push(GTMTypes.ITEM, { productId, colorName });
  GTM.fire(gtmEvent);
};

const debouncedGTMEventsTrigger = _.debounce(triggerGTMEvent, 150);

class ProductDetail extends Component<RouteComponentProps<RouteParams>> {
  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    const {
      match: {
        params: { id: prevId },
      },
      location: { search: prevSearch },
    } = prevProps;
    const { color: prevColor } = getUrlQuery(prevSearch);

    const {
      match: {
        params: { id: newId },
      },
      location: { search },
    } = this.props;
    const { color: newColor } = getUrlQuery(search);
    if (prevId !== newId || prevColor !== newColor) {
      debouncedGTMEventsTrigger({
        productId: newId,
        colorName: newColor,
        gtmEvent: GTMEvents.VIEW_PRODUCT,
      });
    }
  }

  onColorClick = (queryObj: { [key: string]: string }) => (color: string) => {
    const { pathname } = this.props.location;
    const updatedColorUrl = updateURLQuery(pathname, queryObj, { color }, {});
    this.props.history.replace(updatedColorUrl);
  };

  onImageSelect = (queryObj: { [key: string]: string }) => (mockup: string) => {
    const { pathname } = this.props.location;
    const updatedUrl = updateURLQuery(pathname, queryObj, { mockup }, {});
    this.props.history.replace(updatedUrl);
  };

  onSizeGuideClick = (queryObj: { [key: string]: string }) => () => {
    const { pathname } = this.props.location;
    const updatedUrl = updateURLQuery(pathname, queryObj, { sizeGuide: true }, {});
    this.props.history.replace(updatedUrl);
  };

  onCloseSizeGuideModal = (queryObj: { [key: string]: string }) => () => {
    const { pathname } = this.props.location;
    const updatedUrl = updateURLQuery(pathname, queryObj, { sizeGuide: null }, {});
    this.props.history.replace(updatedUrl);
  };

  renderQuoteModal = (opts: {
    state: ProductDetailState;
    onClose: () => void;
    onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
    updateElement: (name: string, value: any) => void;
  }) => {
    const { state, onClose, onEmailChange, onBlur, updateElement } = opts;
    let emailSent: boolean = state.emailSent;
    let content: any = {};
    return (
      <Mutation
        mutation={REQUEST_QUOTE_EMAIL}
        onCompleted={(data: any) => {
          emailSent = data.requestEmailQuote;
          updateElement('emailSent', emailSent);
          setTimeout(onClose, 3500);
        }}
      >
        {(requestEmailQuote: (variables: any) => void) => {
          const onSaveDesign = () => {
            const { items, product, requestedQuotes } = state;
            if (product) {
              const requestedQuoteForQty: RequestLimitedQuoteInput | undefined = requestedQuotes.find((quote) => {
                const {
                  product: { quantity },
                } = quote;
                return quantity === items[0].quantity;
              });
              const printType: PRINT_TYPES_ENUM = requestedQuoteForQty
                ? requestedQuoteForQty.printType
                : PRINT_TYPES_ENUM.DTG;

              const requestEmailQuoteItems = state.items.map((item: RequestEmailQuoteItem) => {
                const colorsInBack: number = typeof item.colorsInBack === 'number' ? item.colorsInBack : 0;
                const colorsInFront: number = typeof item.colorsInFront === 'number' ? item.colorsInFront : 0;
                const detailUrl: string = getProductDetailsLink(product, { color: item.color });
                const designUrl: string = url('app', routes.app.designTool, { product: product.id, color: item.color });
                return {
                  color: item.color,
                  colorsInBack,
                  colorsInFront,
                  hex: item.hex,
                  image: item.image,
                  name: item.name,
                  price: item.price,
                  quantity: item.quantity,
                  detailUrl,
                  designUrl,
                };
              });
              const shareQuoteProduct: RequestEmailQuoteProduct = {
                id: product.id,
                name: product.name,
                brand: product.brand,
                printType,
              };
              requestEmailQuote({
                variables: {
                  to: state.emailQuote,
                  product: shareQuoteProduct,
                  items: requestEmailQuoteItems,
                  requestedQuotes,
                },
              });
            }
          };
          if (!emailSent) {
            content = (
              <Modal onCloseClick={onClose} title="Your Quote">
                <div className="py-1p5 px-2 z-50">
                  <Input
                    className="mb-1p5"
                    type="boxed"
                    label="Email"
                    value={state.emailQuote || ''}
                    onChange={onEmailChange}
                    placeholder="Email"
                    error={!state.emailQuoteIsValid}
                    helper={state.emailQuoteError}
                    onBlur={onBlur}
                  />
                  <PrimaryButton onClick={() => onSaveDesign()}>Send Quote</PrimaryButton>
                </div>
              </Modal>
            );
          } else {
            content = (
              <Modal onCloseClick={onClose} onOverlayClick={onClose}>
                <div className="py-1p5 px-2 z-50 ta-center fs-lg fw-extra-bold">
                  <div>Quote Sent!</div>
                </div>
              </Modal>
            );
          }
          return state.quoteModalOpen ? content : null;
        }}
      </Mutation>
    );
  };

  renderQuoteMenu = (opts: {
    activeColor: ProductColor;
    images: { label: string; url: string }[];
    product: Product;
    productDetailStateContainer: ProductDetailStateContainer;
    onStartDesign: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }) => {
    const { product, activeColor, productDetailStateContainer, images, onStartDesign } = opts;
    const { updateElement } = productDetailStateContainer;
    return (
      <React.Fragment>
        <div className="d-n md:d-b">
          <QuoteCalculatorDesktop
            images={images}
            product={product}
            selectedColor={activeColor}
            productDetailStateContainer={productDetailStateContainer}
            productDetailState={productDetailStateContainer.state}
            onBack={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              return updateElement('showQuoteMenu', false);
            }}
            onStartDesign={onStartDesign}
          />
        </div>
        <div className="d-b md:d-n">
          <QuoteCalculatorMobile
            images={images}
            product={product}
            selectedColor={activeColor}
            productDetailStateContainer={productDetailStateContainer}
            productDetailState={productDetailStateContainer.state}
            onBack={(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              return updateElement('showQuoteMenu', false);
            }}
            onStartDesign={onStartDesign}
          />
        </div>
      </React.Fragment>
    );
  };

  render() {
    const {
      match: {
        params: { id },
      },
      location: { search },
    } = this.props;
    const crumbs: Crumb[] = CreateCrumbsFromURL(this.props.match.path, this.props.match.url);
    const { color: queryColor, mockup, sizeGuide } = getUrlQuery(search);
    return (
      <ProductQuery
        query={PRODUCT_QUERY}
        variables={{ id }}
        partialRefetch={true}
        onCompleted={(data: any) => {
          const { productById } = data;
          const { colors } = productById;
          const activeColors: ProductColor[] = colors;

          let activeColor: ProductColor | undefined = activeColors.find(
            (color: ProductColor) => queryColor === color.name
          );
          if (!activeColor) {
            activeColor = activeColors[0];
          }
          const colorName: string = activeColor ? activeColor.name : '';
          debouncedGTMEventsTrigger({
            productId: id,
            colorName,
            gtmEvent: GTMEvents.VIEW_PRODUCT,
          });
        }}
      >
        {({ data = {}, loading, error, client }) => (
          <Subscribe to={[ProductDetailStateContainer]} namespace="ProductDetail">
            {(productDetailStateContainer: ProductDetailStateContainer) => {
              const {
                state,
                onChangeEmailQuote,
                onBlurEmailQuote,
                setModalState,
                updateElement,
              } = productDetailStateContainer;
              if (loading) {
                return (
                  <div className="w-full h-full flex justify-center items-center bgc-white-70">
                    <Loading size="large" />
                  </div>
                );
              }
              if (error) return <h1>Error loading product</h1>;
              if (!data.productById) return <div>No Product Found!</div>;
              const { productById } = data;
              // TODO: get deliverByDate from graphql
              // TODO: get Description from graphql
              const {
                colors,
                name,
                brand: prettyBrand,
                description: commentString = '',
                image,
                comments: description = '',
                sla,
                relatedProducts,
                categories,
              } = productById;
              const comments = commentString.split('. ').map((string) => `${string}.`);

              const activeColors: ProductColor[] = colors
                .map((color: ProductColor, i) => {
                  return {
                    ...color,
                    active: queryColor ? queryColor === color.name : i === 0,
                  };
                })
                .sort((colorA, colorB) => {
                  const hueDiff = Color(`#${colorA.hex}`).hue() - Color(`#${colorB.hex}`).hue();
                  if (hueDiff !== 0) return hueDiff;
                  return parseInt(colorB.hex, 16) - parseInt(colorA.hex, 16);
                });

              let activeColor: ProductColor | undefined = activeColors.find((color) => queryColor === color.name);
              const startingPriceForActiveColor: number | undefined = activeColor
                ? activeColor.startingPrice
                : undefined;
              if (!activeColor) {
                activeColor = activeColors[0];
              }
              const label: string = activeColor.name;

              let lowestStartingPriceForFilteredColors: number = 0;
              if (activeColors && activeColors.length > 0) {
                lowestStartingPriceForFilteredColors = activeColors.reduce(
                  (prev: number, current: ProductColor): number => {
                    const currentPrice: number = current.startingPrice;
                    if (currentPrice < prev) {
                      return currentPrice;
                    }
                    return prev;
                  },
                  Infinity
                );
              }
              const price: number = startingPriceForActiveColor
                ? startingPriceForActiveColor
                : lowestStartingPriceForFilteredColors;

              let allSizes: ProductSize[] = [];
              activeColors.forEach((color: ProductColor) => {
                if (color.sizesObjects) {
                  return (allSizes = allSizes.concat(color.sizesObjects));
                }
              });
              const sizes = _.uniq(allSizes.filter((size) => size.inStock).map((size) => size.name));
              const date = new Date().toString();

              const images = getProductDetailsImages(productById, activeColor);
              const main = images.find((image) => image.label === mockup) || images[0] || image;
              const labelArray = images.map(({ label }) => label);
              const imageIndex = labelArray.indexOf(mockup);
              const nextImage = images[imageIndex + 1] || images[0];
              const prevImage = images[imageIndex - 1] || images[images.length - 1];

              const brand = (
                categories.find((category) => category.scope === PRODUCT_CATEGORY_SCOPES.BRAND) || { name: 'UNKNOWN' }
              ).name;
              const hasSizeGuide = SIZES_BY_BRAND[brand];

              const onStartDesign = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                const colorName = (activeColor || activeColors[0]).name;
                this.props.history.push(path(routes.app.designTool, { product: productById.id, color: colorName }));
              };

              debouncedGTMEventsTrigger({
                productId: id,
                colorName: activeColor.name,
                gtmEvent: GTMEvents.VIEW_PRODUCT,
              });

              return (
                <React.Fragment>
                  <Helmet>
                    <title>Custom {productById.name} | Design Your Own</title>
                    <meta
                      name="description"
                      content={`Your design + logo + ${productById.name} = Perfection. Design online, ships FREE with no minimums! Guaranteed quality and on-time delivery`}
                    />
                  </Helmet>
                  <div className="w-full px-1 md:w-container md:mx-auto md:p-0">
                    <div className="w-full md:mt-2 md:flex">
                      <div className="breadcrumbs-container my-p5 md:d-n">
                        <Breadcrumbs crumbs={crumbs} />
                      </div>
                      <div className="mt-p5 mb-1 md:w-7/12 md:pr-2 md:mt-0">
                        <Mockups
                          main={main}
                          additional={images}
                          onImageSelect={this.onImageSelect(getUrlQuery(search))}
                          getNextImage={() => this.onImageSelect(getUrlQuery(search))(nextImage.label)}
                          getPrevImage={() => this.onImageSelect(getUrlQuery(search))(prevImage.label)}
                        />
                      </div>
                      <div className="mt-1 mb-2 md:w-5/12 md:m-0">
                        <div className="breadcrumbs-container d-n md:d-b md:mb-1">
                          <Breadcrumbs crumbs={crumbs} />
                        </div>
                        <Details
                          {...{
                            sizes,
                            name,
                            description,
                            activeColor,
                            colors: activeColors,
                            label,
                            onColorSelect: this.onColorClick(getUrlQuery(search)),
                            getByDate: date,
                            onStartDesign,
                            sla,
                            price: Math.round(price / ITEMS_NUMBER_FOR_STARTING_PRICE),
                            // TODO: Get details list and weLoveList from graphQL
                            comments,
                            inStock: !!activeColor && activeColor.inStock,
                            // weLoveList: whyWeLike,
                            onClickGetAQuote: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                              updateElement('showQuoteMenu', true);
                            },
                            onSizeGuideClick: hasSizeGuide ? this.onSizeGuideClick(getUrlQuery(search)) : undefined,
                          }}
                        />
                      </div>
                    </div>
                    <div className="my-2">
                      {relatedProducts && relatedProducts.length > 0 && <Related relatedProducts={relatedProducts} />}
                      <div className="mt-2 mb-4 md:mt-4 md:mb-8">
                        <CantFindProduct />
                      </div>
                      {state.showQuoteMenu &&
                        this.renderQuoteMenu({
                          product: productById,
                          activeColor,
                          productDetailStateContainer,
                          images,
                          onStartDesign,
                        })}
                      {state.quoteModalOpen &&
                        this.renderQuoteModal({
                          state,
                          onClose: () => setModalState(false),
                          onEmailChange: onChangeEmailQuote,
                          onBlur: onBlurEmailQuote,
                          updateElement,
                        })}
                    </div>
                    {sizeGuide && hasSizeGuide && (
                      <Modal
                        onOverlayClick={this.onCloseSizeGuideModal(getUrlQuery(search))}
                        onCloseClick={this.onCloseSizeGuideModal(getUrlQuery(search))}
                        title="Size guide"
                        headerPadding="px-1p25 py-p75"
                        headerTextAlignment=" center"
                      >
                        <SizeGuide className="fs-xs md:fs-sm m-1" product={productById} brand={brand} sizes={sizes} />
                      </Modal>
                    )}
                  </div>
                </React.Fragment>
              );
            }}
          </Subscribe>
        )}
      </ProductQuery>
    );
  }
}

export default ProductDetail;
