import * as React from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { routes } from 'inkp-routes/public';
import {
  LimitedQuoteItems,
  Query as OrderQueryResults,
  QueryrequestLimitedQuoteWithTiersArgs,
  RequestEmailQuoteItem,
  RequestLimitedQuoteOutput,
  ShareQuoteData,
  QuoteTiersValues,
} from 'inkp-order-sdk/types.g';
// import { ITEMS_NUMBER_FOR_STARTING_PRICE } from 'inkp-order-sdk/quoter';
import { QUOTE_QUERY } from '../../states/local/productDetail';
import { Product, ProductColor } from 'inkp-product-sdk';
import { createShareQuoteData } from 'inkp-order-sdk/quotes';

// Components
import PriceDisplayer from 'inkp-components/dist/Components/PriceDisplayer';
import Spinner from 'inkp-components/dist/Components/MobileLeftFilter/Spinner';
import Select, { Option } from 'inkp-components/dist/Components/Select';
import ResultsButton from 'inkp-components/dist/Components/MobileLeftFilter/ResultsButton';

const ITEMS_NUMBER_FOR_STARTING_PRICE = 50;
const purpleFontTable = 'color-purple fs-md fw-bold';
const coralFontTable = 'color-coral fs-sm fw-bold italic line-through';

class LimitedQuoteWithTiersQuery extends Query<OrderQueryResults, QueryrequestLimitedQuoteWithTiersArgs> {}

interface Props {
  amountColorsBack: number | null;
  amountColorsFront: number | null;
  colorsBackOptions: Option[];
  colorsFrontOptions: Option[];
  getQuote: boolean;
  images: { label: string; url: string }[];
  item: LimitedQuoteItems;
  product: Product;
  quote: ShareQuoteData;
  selectedColor: ProductColor;
  showQuote: boolean;
  units: number;

  onAddUnits: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onChangeBack: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onChangeFront: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onChangeUnits: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEmailQuote: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onGetQuoteClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onKeyDownUnits: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onMinusUnits: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onQuoteData: (
    quote: ShareQuoteData,
    emailQuoteItem: RequestEmailQuoteItem,
    requestedQuotes: RequestLimitedQuoteOutput[]
  ) => void;
  onStartDesign: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const renderLoadingProductDetails = () => {
  return (
    <div className="quoter-loading-product-details py-1p5 px-2">
      <div className="w-full flex justify-between mb-1">
        <div className="w-2/5 h-1p5 bgc-navy-200 br-p5"></div>
        <div className="w-1/3 h-1p5 bgc-navy-200 br-p5"></div>
      </div>
      <div className="w-full h-8 bgc-navy-200 br"></div>
    </div>
  );
};

const renderLoadingImprintColors = () => {
  return (
    <div className="quoter-loading-imprint-colors py-1p5 px-2 bc-gray bwt-1">
      <div className="w-1/2 h-1p5 bgc-navy-200 br-p5"></div>
      <div className="w-full h-3 mt-1p5 bgc-navy-200 br-1p5"></div>
      <div className="w-full h-3 mt-1p5 bgc-navy-200 br-1p5"></div>
    </div>
  );
};

const renderLoadingTotalQuantity = () => {
  return (
    <div className="quoter-loading-total-quantity py-1p5 px-2 bc-gray bwt-1">
      <div className="w-1/2 h-1p5 bgc-navy-200 br-p5"></div>
      <div className="w-full h-3 mt-1p5 bgc-navy-200 br-1p5"></div>
    </div>
  );
};

const renderLoadingGetQuoteButton = () => {
  return (
    <div className="quoter-loading-get-quote py-1p5 px-2 bc-gray bwt-1">
      <div className="h-3 bgc-navy-200 br-1p5"></div>
    </div>
  );
};

const renderLoadingQuoteResults = () => {
  return (
    <div className="quoter-loading-results bgc-blue-50">
      <div className="h-3p5 bgc-purple"></div>
      <div className="py-1 px-2">
        <div className="flex justify-between items-end">
          <div className="w-1/3 h-3p5 bgc-navy-200 br"></div>
          <div className="w-2/5 h-2 bgc-navy-200 br"></div>
        </div>
        <div className="w-full">
          <table className="w-full mt-1">
            <thead className="color-navy-500 fs-xs fw-bold">
              <tr>
                <td className="w-1/4 ta-left">
                  <span className="d-ib w-2/3 h-1p5 bgc-navy-200 br-p5"> </span>
                </td>
                <td className="w-1/4 ta-left">
                  <span className="d-ib w-2/3 h-1p5 bgc-navy-200 br-p5"> </span>
                </td>
                <td className="w-1/2 ta-right">
                  <span className="d-ib w-2/3 h-1p5 bgc-navy-200 br-p5"></span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="mt-p5">
                <td className="w-1/4 ta-left">
                  <span className="d-ib w-1/2 h-1p5 bgc-navy-200 br-p5"> </span>
                </td>
                <td className="w-1/4 ta-left">
                  <span className="d-ib w-1/2 h-1p5 bgc-navy-200 br-p5"> </span>
                </td>
                <td className="w-1/2 ta-right">
                  <span className="d-ib w-1/4 h-1p5 bgc-navy-200 br-p5"></span>
                </td>
              </tr>
              <tr className="mt-p5">
                <td className="w-1/4 ta-left">
                  <span className="d-ib w-1/2 h-1p5 bgc-navy-200 br-p5"> </span>
                </td>
                <td className="w-1/4 ta-left">
                  <span className="d-ib w-1/2 h-1p5 bgc-navy-200 br-p5"> </span>
                </td>
                <td className="w-1/2 ta-right">
                  <span className="d-ib w-1/4 h-1p5 bgc-navy-200 br-p5"></span>
                </td>
              </tr>
              <tr className="mt-p5">
                <td className="w-1/4 ta-left">
                  <span className="d-ib w-1/2 h-1p5 bgc-navy-200 br-p5"> </span>
                </td>
                <td className="w-1/4 ta-left">
                  <span className="d-ib w-1/2 h-1p5 bgc-navy-200 br-p5"> </span>
                </td>
                <td className="w-1/2 ta-right">
                  <span className="d-ib w-1/4 h-1p5 bgc-navy-200 br-p5"></span>
                </td>
              </tr>
              <tr className="mt-p5">
                <td className="w-1/4 ta-left">
                  <span className="d-ib w-1/2 h-1p5 bgc-navy-200 br-p5"> </span>
                </td>
                <td className="w-1/4 ta-left">
                  <span className="d-ib w-1/2 h-1p5 bgc-navy-200 br-p5"> </span>
                </td>
                <td className="w-1/2 ta-right">
                  <span className="d-ib w-1/4 h-1p5 bgc-navy-200 br-p5"></span>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="w-2/3 h-1p5 mt-1p5 bgc-navy-200 br-p5"></div>
        </div>
      </div>
    </div>
  );
};

const renderLoadingActions = () => {
  return (
    <div className="quoter-loading-actions w-full py-1p5 px-2 bc-gray bwt-1 flex justify-center flex-wrap">
      <div className="w-full h-3p5 bgc-navy-200 br-full"></div>
      <div className="w-2/5 h-1p5 mt-1 bgc-navy-200 br-full" />
    </div>
  );
};

const renderQuote = (
  onEmailQuote: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  onStartDesign: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  quote: ShareQuoteData,
  amountColorsBack: number | null,
  amountColorsFront: number | null,
  product: Product,
  selectedColor: ProductColor
) => {
  if (amountColorsBack === null && amountColorsFront === null) return;
  if (amountColorsBack === null) amountColorsBack = 0;
  if (amountColorsFront === null) amountColorsFront = 0;
  const sidesWithInks: number = Number(!!amountColorsFront) + Number(!!amountColorsBack);
  const totalInksSelected: number = amountColorsFront + amountColorsBack;
  const oneUnitPrice: number = quote.oneUnitPrice;
  console.log('[DEBUG] quote: ', quote);
  return (
    <React.Fragment>
      <div className={'w-full bgc-purple py-p75 flex items-center justify-center color-white fw-extra-bold'}>
        <span>Your Quote</span>
      </div>
      <div className={'w-full py-1 px-1 bgc-white bgc-blue-50'}>
        <div className="w-1/2 d-ib ta-left">
          <PriceDisplayer
            price={quote.total / quote.quantity}
            sign={'$'}
            numUnits={1}
            priceClassName={'fs-xl'}
            unitsClassName={'fs-sm color-navy-500 fw-bold'}
            label={''}
          />
        </div>
        <div className="w-1/2 d-ib ta-right">
          <span className="color-navy-500 fw-bold fs-md pr-p25">Total</span>
          <span className="color-coral fw-bold fs-md">${(quote.total / 100.0).toFixed(2)}</span>
        </div>
        <div className="w-full">
          <table className="w-full mt-1">
            <thead className="color-navy-500 fs-xs fw-bold">
              <tr>
                <td className="ta-left">Quantity</td>
                <td className="ta-left">Per Piece</td>
                <td className="ta-right">Total</td>
              </tr>
            </thead>
            <tbody>
              {quote.quoteTiersValues.map((qt: QuoteTiersValues, index: number) => (
                <tr key={index} className="mt-p25">
                  <td className="p-relative">
                    {index === 0 ? (
                      <span className="color-coral p-absolute" style={{ right: '103%', top: '-5px' }}>
                        <i className="mdi mdi-play fs-xl" />
                      </span>
                    ) : null}
                    <span className={purpleFontTable}>{qt.quantity}</span>
                  </td>
                  <td className={purpleFontTable}>${(qt.total / qt.quantity / 100.0).toFixed(2)}</td>
                  <td className="ta-right">
                    <span className={`${purpleFontTable} pr-p25`}>${(qt.total / 100.0).toFixed(2)}</span>
                    <span className={coralFontTable}>${((oneUnitPrice * qt.quantity) / 100).toFixed(2)}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="w-full ta-left mt-1p5">
          <span className="color-navy-500 fw-bold fs-xs pr-p25">
            *Approx. price per piece for {sidesWithInks} sided design with {totalInksSelected} ink{' '}
            {totalInksSelected > 1 ? 'colors' : 'color'}
          </span>
        </div>
      </div>
      <div className={'w-full bgc-white py-1p5 flex-col items-center justify-center'}>
        <div className="w-full px-1">
          <ResultsButton className={'fs-xl py-p75'} text={'Start Designing'} onClick={onStartDesign} />
        </div>
        <div className="w-full ta-center mt-1 cursor-pointer" onClick={onEmailQuote}>
          <span className="color-navy fw-bold fs-md">Email My Quote</span>
        </div>
      </div>
    </React.Fragment>
  );
};

const renderQuoteQuery = (
  onQuoteData: (
    quote: ShareQuoteData,
    emailQuoteItem: RequestEmailQuoteItem,
    requestedQuotes: RequestLimitedQuoteOutput[]
  ) => void,
  onEmailQuote: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
  onStartDesign: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  item: LimitedQuoteItems,
  amountColorsBack: number | null,
  amountColorsFront: number | null,
  product: Product,
  selectedColor: ProductColor,
  productImageUrl: string
) => {
  if (amountColorsBack === null && amountColorsFront === null) return;
  if (amountColorsBack === null) amountColorsBack = 0;
  if (amountColorsFront === null) amountColorsFront = 0;
  const { product: quoteProduct } = item;
  const { quantity: itemQuantity } = quoteProduct;
  return (
    <LimitedQuoteWithTiersQuery query={QUOTE_QUERY} variables={{ item }}>
      {({ loading, error, data }) => {
        if (error) {
          console.error(error);
          return <div>error</div>;
        }
        if (loading || !data) {
          return renderLoadingQuoteResults();
        }
        if (!data.requestLimitedQuoteWithTiers) {
          console.error('Item: ', item);
          throw new Error("Couldn't request quote for item.");
        }
        const requestedQuotes: RequestLimitedQuoteOutput[] = data.requestLimitedQuoteWithTiers;
        const quote: ShareQuoteData = createShareQuoteData(requestedQuotes, itemQuantity);

        const emailQuoteItem: RequestEmailQuoteItem = {
          color: selectedColor.name,
          colorsInFront: requestedQuotes[0].quoteSides.FRONT as number,
          colorsInBack: requestedQuotes[0].quoteSides.BACK as number,
          hex: `#${selectedColor.hex}`,
          image: productImageUrl,
          name: product.name,
          price: quote.total,
          quantity: quote.quantity,
          designUrl: '',
          detailUrl: '',
        };
        onQuoteData(quote, emailQuoteItem, requestedQuotes);
        return renderQuote(
          onEmailQuote,
          onStartDesign,
          quote,
          amountColorsBack,
          amountColorsFront,
          product,
          selectedColor
        );
      }}
    </LimitedQuoteWithTiersQuery>
  );
};

const QuoteCalculator: React.FunctionComponent<Props> = ({
  amountColorsBack,
  amountColorsFront,
  colorsBackOptions,
  colorsFrontOptions,
  getQuote,
  images,
  item,
  product,
  quote,
  selectedColor,
  showQuote,
  units,

  onAddUnits,
  onChangeBack,
  onChangeFront,
  onChangeUnits,
  onEmailQuote,
  onGetQuoteClick,
  onKeyDownUnits,
  onMinusUnits,
  onQuoteData,
  onStartDesign,
}) => {
  const frontImage: { label: string; url: string } | undefined = images.find(({ label, url }) => {
    return label.toLowerCase() === 'front';
  });
  const frontImageUrl: string = frontImage ? frontImage.url : '';
  const productColorHex: string = selectedColor.hex;
  const productColorName: string = selectedColor.name;
  const productImageLabel: string = product.image.label;
  const productImageUrl: string = frontImageUrl ? frontImageUrl : product.image.url;
  const productLowestStartingPrice: number = selectedColor.startingPrice;
  const productName: string = product.name;
  let updateClass: string = showQuote || getQuote ? 'bw-1 bc-navy-500 color-navy' : '';
  let updateColor: string = showQuote || getQuote ? 'white' : 'primary';
  // this should be handled by the button instead of here.
  if (units < 1 || (!amountColorsFront && !amountColorsBack)) {
    updateColor = `${updateColor}-500`;
  }
  if (updateClass && (units < 1 || (!amountColorsFront && !amountColorsBack))) {
    updateClass = `${updateClass} color-navy-500`;
  }

  return (
    <React.Fragment>
      <div className="w-full bgc-white">
        <div className="flex items-center justify-between px-2 py-1p5 px-2">
          <span className="fs-md fw-bold">Instant quote for</span>
          {/* <span className="fs-xs fw-bold cursor-pointer">Change Product</span> */}
        </div>
      </div>
      <div className="w-full px-2 pb-1p5 bgc-white">
        <div className="br bw-1 bc-gray minh-8">
          <div className="py-1 pl-1 pr-p5 d-ib">
            <img src={productImageUrl} alt={productImageLabel} style={{ height: '103px', width: '83px' }} />
          </div>
          <div className="py-1 d-ib align-top">
            <div>
              <span className="fw-normal fs-xs">{productName}</span>
            </div>
            <div className="flex items-center pt-p5">
              <div className="br-full w-1p25 h-1p25 d-ib" style={{ backgroundColor: `#${productColorHex}` }} />
              <div className="d-ib color-navy-500 fs-xs pl-p25">{productColorName}</div>
            </div>
            <div className="pt-1p5">
              <PriceDisplayer
                price={Math.round(productLowestStartingPrice / ITEMS_NUMBER_FOR_STARTING_PRICE)}
                sign="$"
                numUnits={50}
                priceClassName="fs-xl"
                unitsClassName="fs-sm color-navy-500 fw-bold"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-2 bwt-1 bc-gray pb-1p5 bgc-white">
        <div className="color-navy fs-md fw-bold mt-1p5">Number of Imprint Colors</div>
        <div className="my-1p5">
          <Select
            options={colorsFrontOptions}
            onChange={onChangeFront}
            placeholder="Colors on Front"
            label="Colors on Front"
            selected={amountColorsFront !== null ? amountColorsFront.toString() : null}
          />
        </div>
        <div>
          <Select
            options={colorsBackOptions}
            onChange={onChangeBack}
            placeholder="Colors on Back"
            label="Colors on Back"
            selected={amountColorsBack !== null ? amountColorsBack.toString() : null}
          />
        </div>
      </div>
      <div className="w-full px-2 bwt-1 bc-gray pb-1p5 bgc-white">
        <div className="color-navy fs-md fw-bold mt-1p5">Total Quantity</div>
        <div className="color-navy fs-md fw-bold mt-1p5 px-2">
          <Spinner
            onAdd={onAddUnits}
            onChange={onChangeUnits}
            onKeyDown={onKeyDownUnits}
            onMinus={onMinusUnits}
            units={units}
          />
        </div>
      </div>
      <div className="w-full py-1p5 px-2 fs-md fw-bold bwt-1 bc-gray bgc-white">
        <ResultsButton
          className={updateClass}
          onClick={onGetQuoteClick}
          text={showQuote || getQuote ? 'Update Quote' : 'Get Quote'}
          bgc={updateColor}
          disabled={units < 1 || (!amountColorsBack && !amountColorsFront)}
        />
      </div>
      {getQuote &&
        renderQuoteQuery(
          onQuoteData,
          onEmailQuote,
          onStartDesign,
          item,
          amountColorsBack,
          amountColorsFront,
          product,
          selectedColor,
          productImageUrl
        )}
      {showQuote &&
        renderQuote(onEmailQuote, onStartDesign, quote, amountColorsBack, amountColorsFront, product, selectedColor)}
    </React.Fragment>
  );
};

export default QuoteCalculator;
