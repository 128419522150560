import { routes } from 'inkp-routes/public';
import { Crumb } from 'inkp-components/dist/Components/Breadcrumbs';
import { UNSPLITTABLE_WORDS, CATEGORIES_MAP } from '../config/breadcrumbs';

const ALL_PRODUCTS_LINK_TEXT: string = 'home';
const T_SHIRTS_PRODUCTS_LINK_TITLE: string = 'Check all these cool t-shirts.';

/**
 * Maps the url segment from the correct category that we identify,
 * so for example: hoodies is mapped to sweatshirts
 * This is needed due to the data we have as categories for the products and the
 * information we want to display as parent category.
 *
 * @param {string} urlSegment
 * @returns {string}
 */
const mapUrlSegment = (urlSegment: string): string => {
  // @ts-ignore
  const mappedUrlSegment: string = CATEGORIES_MAP[urlSegment] || urlSegment;
  return mappedUrlSegment;
};

/**
 * Creates the Crumb object for the path /products/:category/:id which is the one for the ProductDetail
 * @param {string} path - the path to be used in the Crumb.
 * @param {string} urlSegment - the url value for the current segment to process, ex: gildan-cotton-t-shirt
 * @returns {Crumb}
 */
const productDetailResolver = (path: string, urlSegment: string): Crumb => {
  const title: string = '';
  let text: string = urlSegment;

  const unsplittableWord: string | undefined = UNSPLITTABLE_WORDS.find((word: string) => {
    return urlSegment.search(word) > -1;
  });
  if (unsplittableWord) {
    const textBeforeWord: string = urlSegment.substring(0, urlSegment.search(unsplittableWord));
    const textBeforeWordArray: string[] = textBeforeWord.split('-').filter((word: string) => word);
    const textAfterWord: string = urlSegment.substring(urlSegment.search(unsplittableWord) + unsplittableWord.length);
    const textAfterWordArray: string[] = textAfterWord.split('-').filter((word: string) => word);
    text = [...textBeforeWordArray, unsplittableWord, ...textAfterWordArray].join(' ');
  }

  return {
    text,
    path,
    title,
  };
};

/**
 * Creates the Crumb object for the given segment Path.
 * @param {string[]} segmentsMap - The map array for the current segment ['/:category', 't-shirts']
 * @param {Crumb} previousCrumb - The previous Crumb object that was processed
 * @returns {Crumb}
 */
const createCrumbFromSegment = (segmentsMap: string[], previousCrumb: Crumb): Crumb => {
  const pathSegment: string = segmentsMap[0];
  const urlSegment: string = segmentsMap[1];
  const route: string = previousCrumb
    ? previousCrumb.path === '/'
      ? `/products${pathSegment}`
      : `${previousCrumb.path}${pathSegment}`
    : pathSegment;

  const completePath: string = previousCrumb
    ? previousCrumb.text === ALL_PRODUCTS_LINK_TEXT
      ? `/products/${mapUrlSegment(urlSegment)}`
      : `${previousCrumb.path}/${mapUrlSegment(urlSegment)}`
    : '/';

  switch (route) {
    case routes.app.product.base: {
      return {
        text: ALL_PRODUCTS_LINK_TEXT,
        path: completePath,
        title: '',
      };
    }
    case routes.app.product.category: {
      return {
        text: urlSegment,
        path: completePath,
        title: T_SHIRTS_PRODUCTS_LINK_TITLE,
      };
    }
    case routes.app.product.detail: {
      return productDetailResolver(completePath, urlSegment);
    }
    default: {
      return {
        text: urlSegment,
        path: completePath,
        title: '',
      };
    }
  }
};

/**
 * Creates the list of Crumb objects required by the Breadcrumbs component
 * @param {string} path - the path value from the match property of the router
 * @param {string} url - the url value from the match property of the router
 * @returns {Crumb[]}
 */
export const CreateCrumbsFromURL = (path: string, url: string): Crumb[] => {
  const pathSegmentsWithLeadingSlash: string[] | null = path.match(/\/([a-zA-Z0-9-_:]+)/gi);
  const urlSegments: string[] = url.split('/').filter((segment: string) => segment);
  if (pathSegmentsWithLeadingSlash) {
    const segmentsMap: string[][] = pathSegmentsWithLeadingSlash.map((pathSegment: string, index: number) => {
      return [pathSegment, urlSegments[index] || ''];
    });
    const crumbs: Crumb[] = segmentsMap.reduce((crumbs: Crumb[], currentSegment: string[]) => {
      const previousCrumb: Crumb = crumbs[crumbs.length - 1];
      return [...crumbs, createCrumbFromSegment(currentSegment, previousCrumb)];
    }, []);
    return crumbs;
  }
  return [];
};
