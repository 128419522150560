export function getUrlQuery(name: string) {
  const regex = new RegExp(/[?&]([^=#]+)=([^&#]*)/g);
  const params: { [key: string]: string } = {};
  let match;
  while ((match = regex.exec(name))) {
    if (typeof match[2] === 'object') {
      params[match[1]] = JSON.parse(decodeURIComponent(match[2]));
    }
    params[match[1]] = decodeURIComponent(match[2]);
  }
  return params;
}

export function setUrlQuery(obj: { [key: string]: any }) {
  return `filter=${encodeURIComponent(JSON.stringify(obj))}`;
}
